<template>
  <v-container
    class="page-register"
    fill-height
  >

      <v-dialog v-model="showSectorDialog" width="500">
          <v-card>
              <v-card-title class="text-h5 grey lighten-2">
                  {{ $vuetify.lang.t('$vuetify.actions.created', [$vuetify.lang.t('$vuetify.sector.name')])}}
              </v-card-title>

              <v-card-text>
                  <v-text-field
                      v-model="newSector"
                      :label="$vuetify.lang.t('$vuetify.sector.name')"
                      required
                      :rules="formRule.country"
                  ></v-text-field>
              </v-card-text>

              <v-divider></v-divider>

              <v-card-actions>
                  <v-spacer/>
                  <v-btn
                      class="mb-2"
                      color="error"
                      @click="showSectorDialog = false"
                  >
                      <v-icon>mdi-close</v-icon>
                      {{ smallScreen ? '':  $vuetify.lang.t('$vuetify.actions.cancel') }}
                  </v-btn>
                  <v-btn
                      v-show="newSector && newSector.length > 0"
                      class="mb-2"
                      color="success"
                      @click="createNewSector()"
                  >
                      <v-icon>mdi-check</v-icon>
                      {{ smallScreen ? '':  $vuetify.lang.t('$vuetify.sale.state.open') }}
                  </v-btn>
              </v-card-actions>
          </v-card>
      </v-dialog>
    <v-row>
      <v-col>
        <v-card
          class="pa-3 page-register__card"
          tile
        >
          <v-card-title>
            <h1 class="primary--text display-1 text-center">
              {{ $vuetify.lang.t('$vuetify.register') }}
            </h1>
          </v-card-title>
          <v-card-text>
            <v-form
              ref="form"
              v-model="formValid"
              class="my-10"
              lazy-validation
            >
              <v-autocomplete
                v-model="typeAccount"
                :items="getTypeAccount"
                :label="$vuetify.lang.t('$vuetify.type.type_account')"
                :rules="formRule.country"
                item-value="value"
                item-text="text"
                append-icon="mdi-clipboard-account"
                required
              />
              <v-text-field
                v-if="typeAccount !== 'partner'"
                v-model="formRegister.shopName"
                :label="$vuetify.lang.t('$vuetify.company')"
                :rules="formRule.firstName"
                append-icon="mdi-account"
                autocomplete="off"
                name="register"
                required
                type="text"
              />

              <v-text-field
                v-if="typeAccount==='partner'"
                v-model="formRegister.name"
                :label="$vuetify.lang.t('$vuetify.firstName')"
                required
                :rules="formRule.required"
              />
              <v-text-field
                v-if="typeAccount==='partner'"
                v-model="formRegister.last_name"
                required
                :rules="formRule.required"
                :label="$vuetify.lang.t('$vuetify.lastName')"
              />
              <v-text-field
                v-model="formRegister.email"
                :label="$vuetify.lang.t('$vuetify.email')"
                :rules="formRule.email"
                append-icon="mdi-email"
                autocomplete="off"
                name="register"
                required
                type="email"
              />
              <v-text-field
                v-model="formRegister.password"
                :append-icon="
                  hidePassword1 ? 'mdi-eye' : 'mdi-eye-off'
                "
                :label="$vuetify.lang.t('$vuetify.password')"
                :rules="formRule.password"
                :type="hidePassword1 ? 'password' : 'text'"
                autocomplete="off"
                name="password"
                required
                @click:append="hidePassword1 = !hidePassword1"
              />
              <v-text-field
                v-model="formRegister.password_confirmation"
                :append-icon="
                  hidePassword2 ? 'mdi-eye' : 'mdi-eye-off'
                "
                :label="
                  $vuetify.lang.t('$vuetify.confirm_password')
                "
                :rules="passwordConfirmation"
                :type="hidePassword2 ? 'password' : 'text'"
                autocomplete="off"
                name="password_confirmation"
                required
                @click:append="hidePassword2 = !hidePassword2"
              />
              <vue-tel-input-vuetify
                v-model="formRegister.phone"
                append-icon="mdi-castle"
                v-bind="bindProps"
                :error-messages="errorPhone"
                :label="$vuetify.lang.t('$vuetify.phone')"
                :placeholder="
                  $vuetify.lang.t('$vuetify.phone_holder')
                "
                :prefix="
                  countrySelect
                    ? `+` + countrySelect.dialCode
                    : ``
                "
                :rules="formRule.phone"
                :select-label="
                  $vuetify.lang.t('$vuetify.country')
                "
                required
                @input="onInput"
                @keypress="numbers"
                @country-changed="onCountry"
              >
                <template #message="{ key, message }">
                  <slot
                    v-bind="{ key, message }"
                    name="label"
                  />
                  {{ message }}
                </template>
              </vue-tel-input-vuetify>
              <v-autocomplete
                v-if="typeAccount=== 'merchant'"
                v-model="formRegister.sector"
                :filter="customFilter"
                :items="localSectors"
                :label="$vuetify.lang.t('$vuetify.sector.name')"
                :rules="formRule.country"
                append-icon="mdi-castle"
                item-value="value"
                required
              >
                <template v-slot:selection="data">
                  <v-chip
                    v-bind="data.attrs"
                    :input-value="data.item.value"
                    @click="data.select"
                  >
                    {{
                          data.item.default ? $vuetify.lang.t(
                        '$vuetify.sector.' +
                          data.item.value
                      ) : data.item.value
                    }}
                  </v-chip>
                </template>
                <template v-slot:item="data">
                  <template
                    v-if="typeof data.item !== 'object'"
                  >
                    <v-list-item-content
                      v-text="data.item.default ?
                        $vuetify.lang.t(
                          '$vuetify.sector.' +
                            data.item.value
                        ) : data.item.value
                      "
                    />
                  </template>
                  <template v-else>
                    <v-list-item-content>
                      <v-list-item-title>
                        {{data.item.default ?
                          $vuetify.lang.t(
                            '$vuetify.sector.' +
                              data.item.value
                          ):
                          data.item.value
                        }}
                      </v-list-item-title>
                    </v-list-item-content>
                  </template>
                </template>
                  <template v-slot:append-outer>
                      <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                              <v-icon
                                  v-bind="attrs"
                                  v-on="on"
                                  @click="showSectorDialog = true"
                              >
                                  mdi-plus
                              </v-icon>
                          </template>
                          <span>{{ $vuetify.lang.t("$vuetify.titles.newAction") }}</span>
                      </v-tooltip>
                  </template>
              </v-autocomplete>
                <v-menu
                    v-if="typeAccount==='partner'"
                    v-model="menu2"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="auto"
                >
                    <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                            v-model="date"
                            :label="$vuetify.lang.t('$vuetify.bornDate')"
                            readonly
                            :hint="$vuetify.lang.t('$vuetify.hints.format_date')"
                            required
                            :rules="formRule.required"
                            persistent-hint
                            v-bind="attrs"
                            v-on="on"
                        ></v-text-field>
                    </template>
                    <v-date-picker
                        v-model="date"
                        @input="menu2 = false"
                    ></v-date-picker>
                </v-menu>
              <v-select
                v-if="typeAccount==='partner'"
                v-model="formRegister.sex"
                item-text="text"
                item-value="value"
                :items="getSex"
                :label="$vuetify.lang.t('$vuetify.sex.text')"
                required
                :rules="formRule.required"
              />
              <v-select
                v-if="typeAccount==='partner'"
                v-model="formRegister.level_student"
                item-text="text"
                item-value="value"
                :items="getLevelStudent"
                :label="$vuetify.lang.t('$vuetify.level_student.text')"
                required
                :rules="formRule.required"
              />
              <v-text-field
                v-if="typeAccount==='partner'"
                v-model="formRegister.address"
                :label="$vuetify.lang.t('$vuetify.address')"
                :rules="formRule.required"
              />
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  v-bind="attrs"
                  class="mr-3"
                  v-on="on"
                  @click="$router.push({ name: 'login' })"
                >
                  mdi-chevron-left
                </v-icon>
              </template>
              <span>{{ $vuetify.lang.t('$vuetify.login') }}</span>
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  v-bind="attrs"
                  class="mr-3"
                  v-on="on"
                  @click="$router.push({ name: 'home' })"
                >
                  mdi-home
                </v-icon>
              </template>
              <span>{{ $vuetify.lang.t('$vuetify.menu.home') }}</span>
            </v-tooltip>
            <v-spacer />
            <v-btn
              v-if="typeAccount!=='partner'"
              :disabled="!formValid || loading"
              :loading="loading"
              color="primary"
              @click="registerUser"
            >
              <v-icon>mdi-account-plus</v-icon>
              {{ $vuetify.lang.t('$vuetify.register') }}
            </v-btn>
            <v-btn
              v-else
              :disabled="!formValid || loading"
              :loading="loading"
              color="primary"
              @click="solicitePartnerHandler"
            >
              <v-icon>mdi-account-plus</v-icon>
              {{ $vuetify.lang.t('$vuetify.actions.solicit') }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'

export default {
  name: 'Register',
  data () {
    return {
      localSectors: [],
      newSector: '',
      showSectorDialog: false,
      menu: false,
      menu2: false,
      date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
      dateFormatted: null,
      // date: new Date().toISOString().substr(0, 10),
      errorPhone: null,
      typeAccount: 'merchant',
      countrySelect: null,
      loading: false,
      formValid: false,
      hidePassword1: true,
      hidePassword2: true,
      formRule: this.$rules,
      passwordConfirmation: [
        v =>
          !!v ||
            this.$vuetify.lang.t('$vuetify.rule.required', [
              this.$vuetify.lang.t('$vuetify.confirm_password')
            ]),
        v =>
          (!!v && v) === this.formRegister.password ||
            this.$vuetify.lang.t(
              '$vuetify.rule.match',
              [this.$vuetify.lang.t('$vuetify.password')],
              [this.$vuetify.lang.t('$vuetify.confirm_password')]
            )
      ]
    }
  },
  computed: {
    ...mapState('auth', ['isLoggedIn', 'formRegister']),
    ...mapGetters(['errors']),
    ...mapState('statics', ['arrayCountry', 'arraySector', 'smallScreen']),
    bindProps () {
      return {
        mode: 'national',
        clearable: true,
        disabledFetchingCountry: false,
        autocomplete: 'off',
        dropdownOptions: {
          disabledDialCode: false
        },
        inputOptions: {
          showDialCode: false
        }
      }
    },
    getSex () {
      return [
        { value: 'F', text: this.$vuetify.lang.t('$vuetify.sex.f') },
        { value: 'M', text: this.$vuetify.lang.t('$vuetify.sex.m') }
      ]
    },
    getLevelStudent () {
      return [
        { value: 'primary', text: this.$vuetify.lang.t('$vuetify.level_student.primary') },
        { value: 'preparatory', text: this.$vuetify.lang.t('$vuetify.level_student.preparatory') },
        { value: 'university', text: this.$vuetify.lang.t('$vuetify.level_student.university') },
        { value: 'master', text: this.$vuetify.lang.t('$vuetify.level_student.master') },
        { value: 'doctor', text: this.$vuetify.lang.t('$vuetify.level_student.doctor') },
        { value: 'other', text: this.$vuetify.lang.t('$vuetify.level_student.other') }
      ]
    },
    getTypeAccount () {
      return [
        { text: this.$vuetify.lang.t('$vuetify.type.merchant'), value: 'merchant' },
        { text: this.$vuetify.lang.t('$vuetify.type.partner'), value: 'partner' }
        // { text: this.$vuetify.lang.t('$vuetify.type.beneficiary'), value: 'beneficiary' }
      ]
    }
  },
  async created () {
    this.localSectors = this.arraySector
    if (this.$route.params.hash) {
      const affiliateId = this.$route.params.hash.split('=')
      if (affiliateId.length > 0 && affiliateId[0] === 'ref') {
        await this.sendAffiliateRequest(this.$route.params.hash)
      }
      // else {
      //     const data = JSON.parse(atob(this.$route.params.hash))
      //     if (data) {
      //       this.formRegister.shopName = data.name
      //       this.formRegister.email = data.email
      //     }
      //   }
      this.formRegister.affiliate_id = this.$route.params.hash
    } else {
      this.$cookies.remove('referral')
    }
  },
  methods: {
    ...mapActions('auth', ['sendRegisterRequest', 'sendAffiliateRequest', 'sendRegisterPartner']),
    createNewSector () {
      this.localSectors.push({
        value: this.newSector,
        default: false
      })
      this.showSectorDialog = false
      this.formRegister.sector = this.newSector
      this.newSector = ''
    },
    customFilter (item, queryText, itemText) {
      return (
        this.$vuetify.lang
          .t('$vuetify.sector.' + item.value)
          .toLowerCase()
          .indexOf(queryText.toLowerCase()) > -1
      )
    },
    onCountry (event) {
      console.log(event)
      this.formRegister.country = event.iso2
      this.countrySelect = event
    },
    numbers (event) {
      const regex = new RegExp('^[0-9]+$')
      const key = String.fromCharCode(
        !event.charCode ? event.which : event.charCode
      )
      if (!regex.test(key)) {
        event.preventDefault()
        return false
      }
    },
    onInput (number, object) {
      const lang = this.$vuetify.lang
      if (object.valid) {
        this.formRegister.phone = number.replace(' ', '')
        this.errorPhone = null
      } else {
        this.errorPhone = lang.t('$vuetify.rule.bad_phone', [
          lang.t('$vuetify.phone')
        ])
      }
    },
    async registerUser () {
      if (this.$refs.form.validate()) {
        this.loading = true
        this.formRegister.country = this.arrayCountry.filter(
          count => count.id === this.countrySelect.iso2
        )[0]
        await setTimeout(() => {
          this.sendRegisterRequest(this.formRegister)
            .then(() => {
              this.loading = false
            })
            .catch(() => {
              this.loading = false
            })
        }, 1000)
      }
    },
    async solicitePartnerHandler () {
      if (this.$refs.form.validate()) {
        await setTimeout(() => {
          this.sendRegisterPartner(this.formRegister)
            .then(() => {
              this.loading = false
            })
            .catch(() => {
              this.loading = false
            })
        }, 1000)
      }
    },
    parseDate (date) {
      if (!date) return null
      const [month, day, year] = date.split('/')
      return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
    }
  }
}
</script>

<style lang="sass" scoped>
.page-register
  &__card
    max-width: 450px
    margin: 0 auto
    border-radius: 6px !important
</style>
